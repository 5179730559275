import { useCallback, useRef, useEffect } from 'react';
import { useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';
import { useAdditionalDataStorage } from 'containers/AdditionalDataStorage/context';
import { sendDataToParentIframe } from '@hydrapark/api/cart/iframe';

type VoidCallableType = (() => void) | undefined;

export const useSendDataToParentIframeHandler = (
  setError: ((errorMessage: string | undefined) => void),
  setIsLoading: ((isLoading: boolean) => void),
) => {
  const handlerRef = useRef<VoidCallableType>(undefined);
  const { fields } = useGenericFieldsData();
  const { data: additionalData } = useAdditionalDataStorage();

  useEffect(
    () => {
      handlerRef.current = () => {
        sendDataToParentIframe(fields, additionalData).then(() => {
          alert('Page will be redirected. Or show final page here'); // TODO:
        }).catch((er) => {
          setError(String(er));
        }).finally(() => {
          setIsLoading(false);
        });
      };

      return () => {
        handlerRef.current = undefined;
      };
    },
    [additionalData, fields, setError, setIsLoading],
  );

  return useCallback(() => {
    if (handlerRef.current) {
      handlerRef.current();
    }
  }, []);
};
