import { DeepKeys } from 'utils/type-tools';

const customization = {
  /**
   * Exposed project specific variables like logos, email addresses, etc.
   */
  variables: {
    pdf: {
      logo: '/@hydrapark-logo-2.png',
    },
    contactEmail: process.env.REACT_APP_CONTACT_EMAIL,
    contactEmailTitle: 'Custom Assembly Configuration',
  },
  /**
   * CSS variable overrides
   * @see /src/styles/css-variables.scss
   */
  css: {
    // Example 'color-accent': 'red',
  },
  texts: {
    step1: {
      title: 'Select Product',
      tabName: 'Select Product Type',
      description: 'Please use the below options to select the product type you are looking for.',
    },
    step2: {
      title: 'Select Profile',
      tabName: 'Select Profile',
      description: 'Please use the below options to select the profile you are looking for.',
    },
    step3: {
      title: 'Select Material',
      tabName: 'Select Material',
      description: 'Please use the below drop-down option to select material you need.',
    },
    step4: {
      title: 'Measurements',
      tabName: 'Measurements',
      description: 'Please use the below inputs to specify the measurements you need.',
    },
    step5: {
      title: 'Confirm Your Assembly',
      tabName: 'Confirm Assembly',
      // eslint-disable-next-line max-len
      description: 'Please verify component selections, specifications, and assembly length for your application. Custom assemblies are non-returnable and non-refundable. To modify, click the item below.',
    },
    error: {
      noAppropriateConfiguration: 'There are no results that match your criteria. Please select other values.',
    },
    pdf: {
      title: 'Your Assembly',
      // eslint-disable-next-line max-len
      tos: 'By submitting this order, you accept full responsibility for product use, including risks of damage, injury, or death. Verify component specifications before purchasing. Custom products are non-returnable and non-refundable. HydraPak Seals disclaims all warranties, express or implied, and limits liability to product replacement only. Misuse voids any claims. These terms are governed by Utah law. By placing this order, you agree to these terms.',
    },
    nav: {
      profile: 'Profile',
      product: 'Product Type',
    },
  },
} as const;

export type Type = typeof customization;
export type Texts = DeepKeys<typeof customization>;

export default customization;
