import { useMemo } from 'react';

import type { GeneratorInputFieldType } from 'common/components/FieldGenerator/types';
import type { DefaultToFormAdaptersFieldType } from 'containers/FormDataAdapter/types';
import type { ConfiguredRecordType } from 'containers/GenericFieldsHandlingLayer/types';

import { ValidationTypes } from 'common/types/fields';
import { LengthType } from 'common/types/additional';
import { isMaxValidationType, isMinValidationType } from 'utils/isMinMaxValidation';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { ROUNDING_MULTIPLIER } from './constants';


/** This only uses inches and centimeters as the length types, will fail at feet */
export const toFixedByLengthType = (value: number | string, lengthTypeFrom: LengthType, lengthTypeTo: LengthType) => {
  if (lengthTypeFrom === lengthTypeTo) {
    return Math.round(Number(value) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
  }
  if (lengthTypeTo === LengthType.centimeters) {
    return Math.round((Number(value) * 2.54) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
  }
  return Math.round((Number(value) / 2.54) * ROUNDING_MULTIPLIER) / ROUNDING_MULTIPLIER;
};

export const MinValueAttr = 'MinValue';
export const MaxValueAttr = 'MaxValue';

export const useTranslateAssemblyLengthToAppropriateLengthType = <TField = DefaultToFormAdaptersFieldType>(
  field: HydraparkProjectFields,
  fields: ConfiguredRecordType<HydraparkProjectFields, TField>,
  lengthType: LengthType,
) => useMemo(() => {
    if (fields[field]) {
      const adoptedALF = {
        ...fields[field],

      } as GeneratorInputFieldType; // we need hardcoded type for this field to support custom validation dependent on lengthType
      let minValValidatorOverride: number | undefined;
      let maxValValidatorOverride: number | undefined;
      let titleOverride: string | undefined;
      if (adoptedALF.value?.attributes?.[MinValueAttr]) {
        const evalMinValue = adoptedALF.value.attributes[MinValueAttr];
        const numberValue = Number(evalMinValue.replace(/[^0-9.-]+/g, ''));
        const titleValue = evalMinValue.replace(/[^a-zA-Z]+/g, '');
        if (!Number.isNaN(numberValue) && titleValue) {
          minValValidatorOverride = numberValue;
          titleOverride = titleValue;
        }
      }
      if (adoptedALF.value?.attributes?.[MaxValueAttr]) {
        const evalMaxValue = adoptedALF.value.attributes[MaxValueAttr];
        const numberValue = Number(evalMaxValue.replace(/[^0-9.-]+/g, ''));
        const titleValue = evalMaxValue.replace(/[^a-zA-Z]+/g, '');
        if (!Number.isNaN(numberValue) && titleValue) {
          maxValValidatorOverride = numberValue;
          titleOverride = titleValue;
        }
      }
      if (adoptedALF.validation) {
        adoptedALF.validation = adoptedALF
          .validation
          .map((validationObj) => {
            switch (validationObj.type) {
              case ValidationTypes.range: {
                return {
                  ...validationObj,
                  ...(isMinValidationType(validationObj)
                    ? { minValue: toFixedByLengthType(minValValidatorOverride || validationObj.minValue, LengthType.inches, lengthType) }
                    : {}),
                  ...(isMaxValidationType(validationObj)
                    ? { maxValue: toFixedByLengthType(maxValValidatorOverride || validationObj.maxValue, LengthType.inches, lengthType) }
                    : {}),
                };
              }
              default:
                return validationObj;
            }
          });
      }
      if (titleOverride) {
        // adoptedALF.title = titleOverride
      }
      if (adoptedALF.value?.selected) {
        const { value } = adoptedALF.value.selected;
        if (value) {
          adoptedALF.value = {
            ...adoptedALF.value,
            selected: {
              value: toFixedByLengthType(Number(value), LengthType.inches, lengthType),
            },
          };
        }
      }

      return adoptedALF;
    }
    return undefined;
  }, [fields, lengthType]);
