import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import { FormLoader } from 'common/components/Loader';
import { LengthType } from 'common/types/additional';
import {
  STEP_PRODUCT,
  STEP_PROFILE,
  STEP_MATERIALS,
} from '@hydrapark/containers/FormSwitcher/stepNames';
import { scrollToEmptyInput } from 'utils/scrollToInput';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { isFieldDisabled } from 'utils/isStatusDisabled';
import FieldGenerator from 'common/components/FieldGenerator';
import { FieldsType } from 'common/types/fields';
import { QuantityConnected } from './components/QuantityConnected';


import TosCheckboxConnected from './components/TosCheckboxConnected';

import styles from './styles.module.scss';
import { PrintSection } from './PrintSection';
import { useSummary } from './useSummary';
import { Item } from './Item';


const SummaryView = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  onStepChange,
  children,
  isNextDisabled,
  clearProgressError,
  isLoading: isCartLoading,
}: WithChildren<IViewProps<HydraparkProjectFields>>) => {
  const idForm = 'form-summary';
  const { error, isLoading, order } = useGenericFieldsDataAdopted<IFormValues<HydraparkProjectFields>>();
  const { getFieldsByName, updateFieldsState } = useGenericFieldsActionsAdopted<IFormValues<HydraparkProjectFields>>();

  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    getFieldState,
    watch,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    setError,
    resetField,
  } = useForm<IFormValues<HydraparkProjectFields>>({ defaultValues });

  const tosAgreed = watch('tosAgreed');

  useEffect(() => {
    if (tosAgreed) {
      scrollToEmptyInput();
    }
  }, [tosAgreed]);


  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues<HydraparkProjectFields>> = useCallback((data) => {
    if (tosAgreed) {
      onNextClickHandler();
    } else {
      setError('tosAgreed', { message: 'Please accept these terms' });
    }
  }, [onNextClickHandler, tosAgreed]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  const summary = useSummary();
  const {
    parts,
    product,
    profile,

  } = summary;

  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: HydraparkProjectFields,
    changedValue?: IFormValues<HydraparkProjectFields>[keyof IFormValues<HydraparkProjectFields>],
  ) => {
    if (clearProgressError) { clearProgressError(); }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
        className="print"
      >
        {(isLoading || isCartLoading) && <FormLoader />}
        <div className={styles.itemList}>
          <Item
            onClick={() => onStepChange(STEP_PRODUCT)}
            label="Product Type"
            title={product?.title}
            image={product?.attributes?.imagea}
            description={product?.attributes?.description}
          />
          <Item
            onClick={() => onStepChange(STEP_PROFILE)}
            label="Profile"
            title={profile?.title}
            image={profile?.attributes?.imagea}
            description={profile?.attributes?.description}
          />
          {parts.map((part, index) => (
            <Item
              key={part.material?.id}
              onClick={() => onStepChange(STEP_MATERIALS)}
              label={`Material #${index + 1}: ${part.material?.title}`}
              title={part.unit === LengthType.inches
                ? (`${part.size1 ?? 'N.A.'}" ID ${part.size2 ?? 'N.A.'}" OD`)
                : (`${part.size1 ?? 'N.A.'}cm ID ${part.size2 ?? 'N.A.'} cm OD`)}
            />
          ))}
        </div>
        <div className={styles.other}>

          {order && order.map((fieldName) => {
            if (
              fieldsNames.includes(fieldName)
                && !!fields[fieldName]
                && !isFieldDisabled(fields[fieldName])
            ) { // Note addition of isFieldDisabled(fields[fieldName]) here
              return (
                <FieldGenerator<IFormValues<HydraparkProjectFields>>
                  field={fields[fieldName]!}
                  name={fields[fieldName]!.name as HydraparkProjectFields}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  getFieldState={getFieldState}
                  key={fields[fieldName]!.name}
                  onChangeHandler={handleFieldUpdate}
                  isDisabled={isFieldDisabled(fields[fieldName])}
                  resetField={resetField}
                />
              );
            }
            return null;
          })}
          <div className={styles.quantity}>
            <QuantityConnected />
          </div>
        </div>
        <div className={styles.tos}>
          <TosCheckboxConnected control={control} watch={watch} setValue={setValue} />
        </div>
        {children}
      </form>
      <div className={styles.desktop}>
        <ViewsButtonsBlock
          idForm={idForm}
          onResetClickHandler={onResetHandler}
          onBackClickHandler={onBackClickHandler}
          nextButtonText="texts.btn.complete"
          isNextDisabled={isNextDisabled || !tosAgreed}
        />
      </div>
      <PrintSection summary={summary} />
      <div className={styles.mobile}>
        <ViewsButtonsBlock
          idForm={idForm}
          onResetClickHandler={onResetHandler}
          onBackClickHandler={onBackClickHandler}
          nextButtonText="texts.btn.complete"
          isNextDisabled={isNextDisabled || !tosAgreed}
        />
      </div>
    </FormWrapper>
  );
};

// @ts-ignore
export default SummaryView;
