/* eslint-disable max-len */
import { IServerItemOption } from 'common/types/fields';
import rawPrintStyle from 'utils/print';
import { LengthType } from 'common/types/additional';
import { TranslationFn } from 'utils/useTranslateFn';
import { TranslationKey } from '@hydrapark/utils/text-translations';

export const ItemTemplate = ({
  title, image, label, className, description,
}: { title?: string, image?: string, className?: string, label: string, description?: string }) => `
   <div class="item ${className}">
    <a>${label}</a>
    <div>
      ${image ? `<img src="${image}" alt="" />` : ''}
      <div>${title}</div>
    </div>
    ${description ? `<div class="description">${description}</div>` : ''}
  </div>
`;

export const DocumentTemplate = ({
  product,
  parts,
  profile,
  notes,
}: {
        product?: IServerItemOption,
        profile?: IServerItemOption,
        parts: Array<{material?: IServerItemOption, size1?: number, size2?: number, unit: LengthType}>
        notes?: string,
    }, quantity: number, t: TranslationFn<TranslationKey>) => {
  const items = [
    ItemTemplate({
      title: product?.title,
      description: product?.attributes?.description,
      label: 'Product Type',
    }),
    ItemTemplate({
      title: profile?.title,
      image: profile?.attributes?.imagea,
      description: profile?.attributes?.description,
      label: 'Profile',
    }),
    ...parts.map((part, index) => ItemTemplate({
      title: part.unit === LengthType.inches ? (`${part.size1 ?? 'N.A.'}" ID ${part.size2 ?? 'N.A.'}" OD`) : (`${part.size1 ?? 'N.A.'}cm ID ${part.size2 ?? 'N.A.'} cm OD`),
      label: `Material #${index + 1}: ${part.material?.title!}`,
    })),
    notes ? ItemTemplate({ title: notes, label: 'Notes' }) : '',
    quantity ? ItemTemplate({ title: quantity.toFixed(0), label: 'Assembly Qty' }) : '',
  ];
  const img = t('variables.pdf.logo').startsWith('http') ? t('variables.pdf.logo') : `https://${window.location.host}${t('variables.pdf.logo')}`;

  return `<html>
    <head>
        <style>
            ${rawPrintStyle}
        </style>
    </head>
    <body class="print">
        <div class="print-body">
            <img src="${img}" class="logo" />
            <h1>${t('texts.pdf.title')}</h1>
            <p>${t('texts.pdf.tos')}</p>
            ${items.join('')}
        </div>
    </body>
</html>`;
};
