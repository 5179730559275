import { DeepKeys } from '../utils/type-tools';

const customization = {
  /**
   * Exposed project specific variables like logos, email addresses, etc.
   */
  variables: {
    pdf: {
      logo: '/@arg-pdf-logo.png',
    },
    contactEmail: process.env.REACT_APP_CONTACT_EMAIL,
  },
  /**
   * CSS variable overrides
   * @see /src/styles/css-variables.scss
   */
  css: {

  },
  texts: {
    btn: {
      next: 'Next',
      prev: 'Back',
      reset: 'Reset Values',
      complete: 'Complete My Assembly',
    },
  },
} as const;

export type Type = typeof customization;
export type Texts = DeepKeys<typeof customization>;

export default customization;
