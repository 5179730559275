import { useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';
import { useData } from 'containers/AdditionalDataStorage/context';
import { getSingleNumberValue, getSingleOption, getSingleStringValue } from 'api/cart/utils';
import { mapProjectFieldsToProjectHydraparkCart, partVariables } from '@hydrapark/api/cart/iframe';
import { IFormValues } from 'common/components/FormHF/types';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { LengthType } from 'common/types/additional';
import { toFixedByLengthType } from '../MeasurmentsView/utils';

export const useSummary = () => {
  const { fields: currentFields } = useGenericFieldsData<IFormValues<HydraparkProjectFields>>();
  const additionalData = useData();

  const product = getSingleOption(currentFields.hpProductTypeAttribute?.value?.selected);
  const notes = getSingleStringValue(currentFields.notes?.value?.selected);
  const profile = getSingleOption(currentFields.hpProfileAttribute?.value?.selected);
  const parts = partVariables.map((part) => ({
    material: getSingleOption(currentFields[part.material]?.value?.selected),
    size1: currentFields[part.size1]?.value?.selected
      ? toFixedByLengthType(getSingleNumberValue(currentFields[part.size1]?.value?.selected)!, LengthType.inches, additionalData.lengthType)
      : undefined,
    size2: currentFields[part.size2]?.value?.selected
      ? toFixedByLengthType(getSingleNumberValue(currentFields[part.size2]?.value?.selected)!, LengthType.inches, additionalData.lengthType)
      : undefined,
    unit: additionalData.lengthType,
  })).filter((part) => part.material);
  const { generatedConfigId } = mapProjectFieldsToProjectHydraparkCart(currentFields, additionalData);
  const quantity = additionalData.configQuantity;


  return {
    product,
    profile,
    parts,
    generatedConfigId,
    notes,
    quantity,
  };
};

export type Summary = ReturnType<typeof useSummary>;
