// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { FieldsType } from 'common/types/fields';
import { SelectedConfiguration } from 'common/types/additional';
import { useActions } from 'containers/AdditionalDataStorage/context';

import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import FieldGenerator from 'common/components/FieldGenerator';
import { FormLoader } from 'common/components/Loader';

import { useIsNextStepAvailable } from 'hooks/useIsNextStepAvailable';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { isFieldDisabled } from 'utils/isStatusDisabled';
import RadioButtonContainer from './components/RadioButton';

import styles from './styles.module.scss';


const ProductHoseSelectorView = ({
  onNextClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  isNextDisabled,
  children,
  clearProgressError,
  handleSetNoFieldsAvailable,
}: WithChildren<IViewProps<HydraparkProjectFields>>) => {
  const idForm = 'form-product-selector';

  const { error, isLoading, order } = useGenericFieldsDataAdopted<IFormValues<HydraparkProjectFields>>();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted<IFormValues<HydraparkProjectFields>>();
  const { setData } = useActions();

  useEffect(() => {
    setData('activeSelectedConfiguration', SelectedConfiguration.productType);

    return () => setData('activeSelectedConfiguration', null);
  }, [setData]);

  const fields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
  } = useForm<IFormValues<HydraparkProjectFields>>({ defaultValues });

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues<HydraparkProjectFields>> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);

  const renderRadioButton = useCallback(() => RadioButtonContainer, []);


  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: HydraparkProjectFields,
    changedValue?: IFormValues<HydraparkProjectFields>[keyof IFormValues<HydraparkProjectFields>],
  ) => {
    if (clearProgressError) { clearProgressError(); }
    updateFieldsState(fieldType, fieldName, changedValue);
  }, [updateFieldsState, clearProgressError]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  useIsNextStepAvailable(order, fieldsNames, fields, handleSetNoFieldsAvailable);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {isLoading && <FormLoader />}
        {order && order.map((fieldName) => {
          if (fieldsNames.includes(fieldName) && !!fields[fieldName]) {
            return (
              <FieldGenerator<IFormValues<HydraparkProjectFields>>
                field={fields[fieldName]!}
                name={fields[fieldName]!.name as HydraparkProjectFields}
                control={control}
                watch={watch}
                setValue={setValue}
                className={styles.grid}
                getFieldState={getFieldState}
                key={fields[fieldName]!.name}
                onChangeHandler={handleFieldUpdate}
                isDisabled={isFieldDisabled(fields[fieldName])}
                resetField={resetField}
                renderOptionLabelComponent={renderRadioButton}
              />
            );
          }
          return null;
        })}
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        isNextDisabled={isNextDisabled || !!error}
        isBackHidden
      />
    </FormWrapper>
  );
};

// @ts-ignore
export default ProductHoseSelectorView;
